import React, {  useState } from 'react'
import "./TestimonialLatestPost.css"
import { BlogsData } from '../../../DummyData/BlogsData'
const TestimonialLatestPost = () => {
    const [Data, setData] = useState(true)
    const HandleOnclick = () => {
        setData(Data ? false : true)
    }

    return (
        <div className="TestimonialLatestPost-Main">
            <div className="TestimonialLatestPost-Lable">
                <h2>Latest post</h2>
            </div>
            {/* <div className="TestimonialLatestPost-Filter d-flex pb-2" style={{ float: "left" }}>
                <select style={{ width: `clamp(80px, 7.5vw, 150px)`, height: "40px", borderRadius: "6px",margin:'5px' }}>

                    <option value='All'>All</option>
                    <option value='Tech'>Tech</option>
                    <option value='Marketing'>Marketing</option>
                    <option value='Business'>Business</option>
                </select>
                <select style={{ width: `clamp(80px, 7.5vw, 150px)`, height: "40px", borderRadius: "6px" ,margin:'5px'}}>

                    <option value='All'>All</option>
                    <option value='Tech'>Tech</option>
                    <option value='Marketing'>Marketing</option>
                    <option value='Business'>Business</option>
                </select>
                <input type='button' value="Reset all" />
            </div> */}
            <div className='TestimonialLatestPost-inner overflow-hidden' >
                {Data ? <>{BlogsData.slice(0, 6).map((each, index) => (
                    <div className="TestimonialLatestPost-Des" key={index} data-aos="zoom-in-right">

                        <div className="TestimonialLatestPost-Photo">
                            <img src={each.Photo} alt='' />

                        </div>
                        <div className="TestimonialLatestPost-Des-Inner">
                            <div className="TestimonialLatestPost-Profile">
                                <img src={each.Profile} alt='' />
                                <div className="TestimonialLatestPost-Profile1">
                                    <p>{each.Name}</p>
                                    <p>{each.Date}</p>
                                </div>

                            </div>

                            <div className="TestimonialLatestPost-Heading">
                                <h3>{each.Heading}</h3>

                            </div>

                        </div>
                        <input type='button' value="View Success Story" />


                    </div>
                ))}
                </> : <>{BlogsData.map((each, index) => (
                    <div className="TestimonialLatestPost-Des" key={index}>

                        <div className="TestimonialLatestPost-Photo">
                            <img src={each.Photo} alt='' />

                        </div>
                        <div className="TestimonialLatestPost-Des-Inner">
                            <div className="TestimonialLatestPost-Profile">
                                <img src={each.Profile} alt='' />
                                <p>{each.Name}</p>
                                <p>{each.Date}</p>
                            </div>

                            <div className="TestimonialLatestPost-Heading">
                                <h3>{each.Heading}</h3>

                            </div>

                        </div>

                        <input type='button' value="View Success Story" />

                    </div>
                ))}</>}

            </div>
            <button  onClick={HandleOnclick}>{Data ? "Load More" : 'Load Less'}</button>
        </div>

    )
}

export default TestimonialLatestPost