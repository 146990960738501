import React from 'react'
import AboutTeamSliderMob from './AboutTeamSliderMob/AboutTeamSliderMob';
import AboutTeamTab from "./AboutTeamTab/AboutTeamTab"
import { useMediaQuery } from 'react-responsive'

const AboutTeamMobTab = () => {

// const width = window.innerWidth;
  const isDesktopOrLaptopa = useMediaQuery({ query: '(max-width: 712px)' });
  // console.log(width)
  return (
    <div>
      {isDesktopOrLaptopa ? <AboutTeamSliderMob /> : <AboutTeamTab />}

    </div>
  )
}

export default AboutTeamMobTab