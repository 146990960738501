import { FAQSQuestion } from '../../../../DummyData/FAQSData/FAQSQuestion';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OurProduct.css';
import Up from "../../../../Assests/Logo/upload.png";
import Down from "../../../../Assests/Logo/arrow-down-sign-to-navigate.png";

const OurProduct = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/faqs');
        setFaqs(response.data);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <div className="accordion">
      {faqs.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
            onClick={() => onTitleClick(index)}
          >
            {item.title}
            <span className="icon">
              {activeIndex === index ? (
                <img src={Up} alt="Up Icon"/>
              ) : (
                <img src={Down} alt="Down Icon"/>
              )}
            </span>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'active' : 'hidden'}`}>
            {item.description.replace(/<\/?[^>]+(>|$)/g, "")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OurProduct;
