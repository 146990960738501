import React from 'react'
import "./CompareAnalysis.css"
import { NavLink } from 'react-router-dom'
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const CompareAnalysis = () => {
    return (
        <div className='CompareAnalysis'>
            <div className="CompareAnalysis-1" data-aos="zoom-in-right">
                <h2>
                Variety of report and analysis Get 
                Through Reviews Faster.
                </h2>
                <p>
                DynamicEMR offers a diverse range of reports and analyses, enabling healthcare providers to swiftly review and interpret critical data. Accessing detailed, real-time insights helps streamline decision-making processes, improve patient care, and enhance operational efficiency, ultimately allowing providers to get through reviews faster and more effectively.
                </p>
            </div>
            <div className="CompareAnalysis-2" data-aos="zoom-in-left">
                <div className="CompareAnalysis-Def">
                    <h2>
                    Comprehensive Reporting and Real Time Data Analysis
                    </h2>
                    <p>
                    DynamicEMR empowers healthcare providers with detailed reports and real-time data insights. Monitor patient health, track treatment outcomes, and make informed decisions quickly. This capability enhances operational efficiency, improves patient care, and enables healthcare facilities to maintain high standards of service and performance.
                    </p>
                    <NavLink to="/BookDemo" data-aos="flip-left">
                        <button className='CompareAnalysis-Def-But' >Book a Demo</button>
                    </NavLink>
                </div>
                <div className="CompareAnalysis-Photo">
                    <img src={Photo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default CompareAnalysis