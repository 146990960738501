 




import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Fixedassests.png"
const  FixedAssestMang= () => {

    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                Fixed Assets Management
                </h2>
                <p>
                Track and manage hospital assets, from medical equipment to infrastructure, ensuring optimal utilization and maintenance.
                </p>

            </div>
        </div>
    )
}

export default FixedAssestMang

