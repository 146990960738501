import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Account.png"
const SampleManagement = () => {
    return (
        <div className="CompareAnalysis-2" data-aos="zoom-in-left" style={{ width: "90%", margin: "auto" }}>
            <div className="CompareAnalysis-Def">
                <h2>

                    Sample Management:</h2>
                <p>
                    The LMS supports comprehensive sample tracking from collection to analysis, ensuring all samples are managed efficiently. This functionality helps in maintaining data integrity throughout the testing process.
                </p>

            </div>
            <div className="CompareAnalysis-Photo">
                <img src={Photo} alt="" />
            </div>
        </div>
    )
}

export default SampleManagement