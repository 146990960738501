import React from 'react'
import "./Blogs.css"
import BlogsType from './BlogsType/BlogsType'
import BlogsLatestPost from './BlogsLatestPost/BlogsLatestPost'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
const Blogs = () => {
  return (
    <div className='Blogs'>
      {/* <LandingBlogs/> */}
      <BlogsType/>
      <BlogsLatestPost/>
      <ContactForDemo/>
    </div>
  )
}

export default Blogs