import React from 'react'
import Manag from "../../../Assests/Risk management, forecasting and assessment.png"
import "./DyanamicManag.css"
const DyanamicManag = () => {
    return (
        <div className='DyanamicManag'>
            <div className="DyanamicManag-Heading">
                <div className='DyanamicManag-Heading-1'>
                    <p>
                        Transforming Healthcare Management
                    </p>
                </div>

                <div className='DyanamicManag-Heading-2'>
                    <p >
                        Dynamic EMR focuses on transforming healthcare management system
                    </p>
                </div>

            </div>

            <div className="DyanamicManag-Des">
                <div className="DyanamicManag-1" data-aos="zoom-out-right">
                    <p  
                >Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management. Our software automates administrative tasks, reduces paperwork, and optimizes workflows, enabling healthcare providers to increase efficiency and productivity. By ensuring a seamless patient journey—from scheduling to follow-up care—we improve patient experiences and satisfaction. Additionally, our robust data management capabilities allow secure storage, easy access, and thorough analysis of patient records, supporting informed decision-making and compliance with healthcare regulations.</p>
                </div>
                <div className="DyanamicManag-2" data-aos="fade-left">
                    <img src={Manag} alt='' />
                </div>
            </div>

        </div>
    )
}

export default DyanamicManag