import React from 'react'
import { NavLink } from 'react-router-dom'
import "./ContactForDemo.css"
const ContactForDemo = () => {
    return (
        <div className='ContactForDemo'>
            <p style={{textAlign:"center"
            }}>Want to know more about Dynamic Emr software </p>
            {/* </div> */}
            {/* <div className="ContactForDemo-But"> */}
            <NavLink to="/BookDemo"><button className="ContactForDemo-But" data-aos="flip-right" style={{whiteSpace:"nowrap"}}>Contact Us For Demo</button></NavLink>
            {/* </div> */}
        </div>
    )
}

export default ContactForDemo