import React from 'react'
import "./AboutUS.css"
import AboutBanner from "../Abouts/Banner/AboutBanner"
import AboutDef from './AboutDef/AboutDef'
import AboutmapSlogan from './AboutmapSlogan/AboutmapSlogan'
import AboutMap from './AboutMap/AboutMap'
import AboutMissCommit from './AboutMap/AboutMissCommit/AboutMissCommit'
import AboutTeamSlider from './AboutTeamSlider/AboutTeamSlider'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
import TestimonilasClientSlider from '../Testimonials/TestimonilasClientSlider/TestimonilasClientSlider'
import ClientSlider from './ClientSlider/ClientSlider'
const AboutUS = () => {
  return (
    <div className='AboutUS'>
      <div className="AboutBanner-lan">
        <AboutBanner />

      </div>
      <div className="AboutDef-lan">
        <AboutDef />

      </div>
      <div className="AboutmapSlogan-lan">
        <AboutmapSlogan />

      </div>
      <div className="AboutMap-lan">
        <AboutMap />

      </div>
      <div className="TestimonilasClientSlider-lan">
        <TestimonilasClientSlider />

      </div>
      <div className="AboutMissCommit-lan">
        <AboutMissCommit />

      </div>
      <div className="AboutTeamSlider-lan">
        <AboutTeamSlider />

      </div>
      <div className="ContactForDemo-Home">

        <ContactForDemo />
      </div>
      <div className="ClientSlider-lan">
        <ClientSlider />

      </div>

    </div>
  )
}

export default AboutUS