export const SSFData = [
    {
        title: "1. Understanding the Social Security Funds (SSF)",
        description: "Social Security Funds primarily support healthcare providers through financial aids and subsidies, particularly for rural health facilities. Programs such as the Healthcare Connect Fund (HCF) offer vital funding to enhance broadband connectivity, enabling healthcare institutions to improve patient care and operational efficiency. HCPs can receive up to a 65% discount on broadband services essential for maintaining modern healthcare IT infrastructure",
    },
    {
        title: "2. Dynamic EMR Hospital Management Systems",
        description: "Dynamic EMR systems are designed to facilitate efficient data management, improve accessibility to patient records, and enhance the overall quality of care. These systems can also help track eligibility for SSF program subsidies, ensuring that healthcare providers can leverage these funds effectively.",
    },
    {
        title: "3. Integration Strategies",
        description: "Integrating Social Security Funds (SSF) with a dynamic Electronic Medical Record (EMR) hospital management system requires a balanced approach involving strategic planning, effective training, and leveraging technology to ensure seamless operations.",
    },
    
];