export const EMRBannerdata=[
    {
        Lable:"Improved Patient Care:",
        Description:"EMRs enable quicker access to patient information, including medical histories and lab results, facilitating better diagnosis and treatment decisions."
    },
    {
        Lable:"Better Coordination of Care:",
        Description:"EMRs facilitate communication between multiple healthcare providers, ensuring that all professionals involved in patient care have access to necessary information, thus improving comprehensive care delivery3.",
    },
    {
        Lable:"Patient Empowerment:",
        Description:"Many EMR systems include patient portals, allowing patients direct access to their health information, which encourages engagement and better adherence to treatment plans."
    },
];