import React, { useEffect } from 'react'
import "./App.css"
import Routing from "./Components/Routing/Routing"
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init(
    );
  }, []);

  return (
    <div className='App'>

      <Routing />


    </div>
  )
}

export default App