import React from 'react'
import AboutTeamMobTab from './AboutTeamMobTab/AboutTeamMobTab';
import { useMediaQuery } from 'react-responsive'
import AboutTeamSliderDesk from './AboutTeamSliderDesk/AboutTeamSliderDesk';
const AboutTeamSlider = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(max-width: 1224px)' });

    const A1 = 1200;
    return (
        <div>
            {isDesktopOrLaptop ? <AboutTeamMobTab /> : <AboutTeamSliderDesk />}

        </div>
    )
}

export default AboutTeamSlider