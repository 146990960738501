import React from 'react'
 



import "./DynamicContactAdd.css"
const DynamicContactAdd = () => {
    return (
        <div className='DynamicContactAdd overflow-hidden'>

            <div className="DynamicContactAdd-inner" data-aos="zoom-in-right">
                <div className="DynamicContactAdd-Lable-1">
                    <label>
                        Our Location?
                    </label>
                </div>
                <div className="DynamicContactAdd-Data-1">

                    <address>
                        Prativa Chowk,NayaSombare
                        Biratnagar,Nepal
                    </address>
                </div>

            </div>
            <div className="DynamicContactAdd-inner" data-aos="zoom-in">
                <div className="DynamicContactAdd-Lable-1">
                    <label>
                        Support
                    </label>
                </div>
                <div className="DynamicContactAdd-Data-1">
                    {/* <Link to={{ pathname: "https://wa.me/9779800445577" }} target="">
                        9800445577</Link> */}
                    <p>
                    +977 9749236223, +977 9815354977
                    </p>  
                   
                </div>
            </div>
            <div className="DynamicContactAdd-inner " data-aos="zoom-in-left">
                <div className="DynamicContactAdd-Lable-1">
                    <label>
                        Our Email Address
                    </label>
                </div>
                <div className="DynamicContactAdd-Data-1">


                    <a href="mailto:example@gmail.com?subject=Hello&body=Hi there!">
                        dynamicemr2021@gmail.com
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DynamicContactAdd