import React from 'react'
import "./HosAnalysis.css"
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const  LabIntegratedBill = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    Integrated Billing:
                </h2>
                <p>
                    The connected Q system allows the laboratory to link billing directly with operational functions. Technicians can view billed requests, facilitating easier sample collection, result reporting, and printing of reports.
                </p>

            </div>
        </div>
    )
}

export default LabIntegratedBill