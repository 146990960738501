import React from 'react'
import "./CompareAnalysis.css"
import { NavLink } from 'react-router-dom'
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const EMRSys = () => {
    return (
        <div className='CompareAnalysis'>
            <div className="CompareAnalysis-1" data-aos="zoom-in-right">
                <h2>
                    Improved the Management Structure for Clinics,Hospitals & Doctors with the DynamicEMR.
                </h2>
                <p>
                    DynamicEMR enables you to digitize every aspect of your interaction with your patient. Simplify the recording of medical information with the help of EMR Software. This easy-to-use system is a blessing for Clinics, Hospitals and Doctors as it allows you to easily record medical data in customizable forms and read the patient history, treatments, diagnosis, and lots more in one place in a jiffy!
                </p>
            </div>
            <div className="CompareAnalysis-2" data-aos="zoom-in-left">
                <div className="CompareAnalysis-Def">
                    <h2>
                        Easy Recording of Medical Data through EMR Systems
                    </h2>
                    <p>
                        EMRs enable healthcare providers to maintain an organized and up-to-date record of a patient's medical history, including diagnoses, treatments, medications, allergies, and laboratory results
                    </p>
                    <NavLink to="/BookDemo" data-aos="flip-left">
                        <button className='CompareAnalysis-Def-But' >Book a Demo</button>
                    </NavLink>
                </div>
                <div className="CompareAnalysis-Photo">
                    <img src={Photo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default EMRSys