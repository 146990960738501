import React from 'react'
import "./AboutBanner.css"
import Typewriter from 'typewriter-effect';
const AboutBanner = () => {
  return (


    <div className="AboutSlogan" style={{
      alignContent: "center"
    }}>
 <Typewriter
        options={{
          strings: ['<p>We’re here to <br/><span >gurantee your success</span></p>'],
          autoStart: true,
          loop: true,
          html: true,
        }}
      />

      


    </div>
  )
}

export default AboutBanner