import React from 'react';
import ScrollProgressCircle from './ScrollProgressCircle';

function ProgressCircle() {
  return (
    <div className="ProgressCircle">
      <ScrollProgressCircle />
    </div>
  );
}

export default ProgressCircle;
