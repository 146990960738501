import React from 'react'
import "./FAQS.css"
import Faqsdata from "../../DummyData/FAQSData/Faqsdata"
import { useState } from "react"
import OurProduct from './Page/OurProduct/OurProduct'
import { NavLink } from 'react-router-dom'
const FAQS = () => {
  const [Index, setindex] = useState(0)
  // console.log(Index)
  return (
    <div className="FAQS-Container">
      <h1>FAQS</h1>
      {/* <div className="FAQS-Item-Container">
        {Faqsdata.map((item, index) => (
          <div className="FAQS-Item" key={index}>

            <p onClick={() => setindex(index)}>{item.Lable}</p>
          </div>
        ))}


      </div>
      {Faqsdata.map((each, index) => (
        <div className="FAQS-Components" key={index}>
          {Index === index && (
            <div className="FAQS-Components-Item" key={index}>
              {each.Component}
            </div>
          )}

        </div>


      ))} */}
       <div className="FAQS-Components" >
           
            <div className="FAQS-Components-Item"  >
            <OurProduct/>
            </div>
        

        </div>
     
      <div className="FAQSContactUs">
        <p>Have Something Not being addressed?</p>
       <NavLink to="/Contact" ><button>Contact Us</button></NavLink>
      </div>

    </div>
  )
}

export default FAQS