import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/compalain.png"
const SupportAndComplain = () => {
    return (
        <div className="CompareAnalysis-2" data-aos="zoom-in-left" style={{ width: "90%", margin: "auto" }}>
            <div className="CompareAnalysis-Def">
                <h2>
                    Support and Complain Management

                </h2>
                <p>
                    Facilitate patient and staff feedback, ensuring that complaints are addressed promptly and support is provided efficiently.
                </p>

            </div>
            <div className="CompareAnalysis-Photo">
                <img src={Photo} alt="" />
            </div>
        </div>
    )
}

export default SupportAndComplain