import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Inventory.png"
const PatientMonitrack = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    Monitoring and Tracking Systems:
                </h2>
                <p>
                    Maintain comprehensive records of all hospital supplies, streamline ordering processes, and ensure that stock levels are optimized at all times.
                </p>

            </div>
        </div>
    )
}

export default PatientMonitrack



