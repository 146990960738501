import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Account.png"
const BillingAndInvoicing = () => {
    return (
        <div className="CompareAnalysis-2" data-aos="zoom-in-left" style={{ width: "90%", margin: "auto" }}>
            <div className="CompareAnalysis-Def">
                <h2>

                Billing and Insurance Management:</h2>
                <p>
                DynamicEMR automates the billing process, handling insurance claims and patient billing seamlessly. It reduces paperwork and minimizes errors, which facilitates timely reimbursements and improves revenue management.
                </p>

            </div>
            <div className="CompareAnalysis-Photo">
                <img src={Photo} alt="" />
            </div>
        </div>
    )
}

export default BillingAndInvoicing