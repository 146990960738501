import React from 'react'
import { useMediaQuery } from 'react-responsive';
import NavigationMob from "./NavigationMob/NavigationMob"
import NavigationaDesk from './NavigationaDesk/NavigationaDesk';
import "./Navigation.css"

const Navigation = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <div className='Navigation' style={{    backgroundColor: "#fcfafa"
        }}>
            {isDesktopOrLaptop ?
                <>
                    <NavigationMob />
                </>
                :
                <><NavigationaDesk /></>

            }


        </div>
    )
}

export default Navigation