import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './OurService.css';
import Arrows from "../../../Assests/OurService/Counter/Vector (3).png";

const OurService = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get("https://api.dynamicemr.net/api/services");
        const data = response.data.map(item => ({
          ...item,
          updatedDescription: item.short_description.replace(/src="\/ckeditor\/kcfinder\/upload\/images/g, `src="https://api.dynamicemr.net/ckeditor/kcfinder/upload/images`)
        }));
        setPosts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUsers();
  }, []);

  return (
    <div className='OurService'>
      <div className="OurService-Lable" data-aos="flip-left">
        <h6>Our Service</h6>
      </div>
      <div className="OurService-Des">
        {posts.map((item, index) => (
          <div className="OurService-Des-Inner" key={index} data-aos="flip-right">
            <div className="OurService-Des-Icon">
              <img src={item.icon_url} alt="" />
            </div>
            <div className="OurService-Des-Lable">
              <p>{item.title}</p>
            </div>
            <div className="OurService-Des-MetaDes">
              <div dangerouslySetInnerHTML={{ __html: item.updatedDescription }}></div>
            </div>
            {/* <div className="OurService-Des-But">
              <div className="OurService-Des-Read">
                <button>Read More</button>
              </div>
              <div className="OurService-Des-Arrow">
                <img src={Arrows} alt="" />
              </div>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurService;
