




import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Fixedassests.png"
const PatientEng = () => {

    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    Patient Engagement Portals:
                </h2>
                <p>
                    It includes a patient portal feature, allowing patients to access their health information, communicate with their healthcare providers, and engage in their care actively.
                </p>

            </div>
        </div>
    )
}

export default PatientEng

