import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavData from '../DummyData/NavData/NavData';
import Navigation from '../Navigation/Navigation';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import Footer1 from '../Footer/Footer1';
import ScrollToTop from '../Shared/ScrollToTop/ScrollToTop';
import "./Routing.css"

const App = () => {
    return (
        <BrowserRouter>
            <Navigation />
            <ScrollToTop />

                <Routes className="Routes-1">
                    {NavData.map((each, index) => (
                        <Route  key={index} path={each.Path} element={each.Component} />
                    ))}
                </Routes>
            <ProgressCircle />
            {/* <Footer /> */}
            <Footer1 />
        </BrowserRouter>
    );
};

export default App;
