import React from 'react'
import "./SSF.css"
import SSf from "../../Components/Assests/ssf_logo.png"
import SSFBan from "../Assests/rb_54343.png"
import { SSFData } from '../DummyData/SSFData'
const SSF = () => {
    return (
        <div className='SSF'>
            <div className="SSF_Banner">
                <img src={SSf} alt='' />
            </div>
            <div className="SSF-Details">
                <div className="SSF-Details-Top">
                    <div className="SSF-Details-Top-1">
                        <h3>
                            SSF(Social Security Funds)
                        </h3>
                        <p>
                            Social Security Funds (SSF) are designed to provide financial support to individuals in various situations, such as retirement, disability, and unemployment. These funds are typically funded through payroll taxes and managed by government agencies.

                            In Nepal, for example, the Social Security Fund (SSF) covers private sector workers and is set to expand to include informal sector and migrant workers. The fund offers several schemes, including medical treatment, health protection, maternity plans, accidents and disability plans, dependent family plans, and old-age security plans
                        </p>
                    </div>
                    <div className="SSF-Details-Top-2">
                        <img src={SSFBan} alt='' />
                    </div>
                </div>
                <div className="SSF-Details-Bottom">
                    <h3>DynamicEMR with SSF</h3>
                    <p>
                        Social Security Funds (SSF) with dynamic electronic medical record (EMR) hospital management systems presents an innovative approach to enhancing healthcare delivery while ensuring compliance with regulations. Here, we outline essential components for this integration based on the relevant literature and established practices
                    </p>
                    {SSFData.map((each, index) => (
                        <>
                            <h4> {each.title}</h4>
                            <p> {each.description}</p>
                        </>

                    ))}

                </div>

            </div>


        </div>
    )
}

export default SSF