import React from 'react'
import "./Banner.css"
import Typewriter from 'typewriter-effect';
import Contact from "../../../Assests/a11e293d-a13e-46fc-9d6f-8c69a9a7abdc.png"
const Banner = () => {
  return (
    <>




      <div className="ConSlogan d-flex " style={{
        alignContent: "center" ,justifyContent:"space-between",flexWrap:"wrap"
      }}>


        <Typewriter
          options={{
            strings: [' <p>Get in touch with us!</p></br><p>Feel free to call us for queries!</P>'],
            autoStart: true,
            loop: true,
          }}

          />

        <img src={Contact} alt=''/>

      </div>
      

    </>

  )
}

export default Banner