import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Inventory.png"
const EMRPatientEngagement = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    EMRPatientEngagement
                </h2>
                <p>
                    Many EMRs offer patient portals, allowing individuals to access their health information, communicate with providers, and manage appointments, which empowers patients to take an active role in their healthcare.
                </p>

            </div>
        </div>
    )
}

export default EMRPatientEngagement



