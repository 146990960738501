import React from 'react'
import "./HosAnaylysisRes.css"
import Photo from "../../../../Assests/Dashgif.gif"
const HosAnaylysisRes = () => {
    return (
        <div className='HosAnaylysisRes'>

            <div className="HosAnaylysisRes-Heading">
                <p className='HosAnaylysisRes-Heading-1' data-aos="fade-right">
                Ready to Take Control of Your
                Data and anlaysis it and get best result?
                </p>
                <p className='HosAnaylysisRes-Heading-2' data-aos="fade-right">
                Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management.
                </p>
            </div>
            <div className="HosAnaylysisRes-Image" data-aos="fade-left">
                <img src={Photo} alt=''/>
            </div>

        </div>
    )
}

export default HosAnaylysisRes