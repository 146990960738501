import React from 'react'
import "./Emr.css"
import HosAnalysisFeature from './HosAnalysisFeature/HosAnalysisFeature'
import HospitalHealthMang from './HospitalHealthMang/HospitalHealthMang'
import HosAnaylysisRes from './HosAnaylysisRes/HosAnaylysisRes'
import ProductSlider from './ProductSlider/ProductSlider'

import ContactForDemo from '../../Home/ContactForDemo/ContactForDemo'
import Survey from './Survey/Survey'
import HumanAttendManag from './HumanAttendManag/HumanAttendManag'
import EMRMangBanner from './HospitalMangBanner/EMRMangBanner'
import EMRSys from './CompareAnalysis/EMRSys'
import EMREPrescribing from './HosAnalysis/EMREPrescribing'
import EMRInteroperability from './AccountManag/EMRInteroperability'
import EMRPatientEngagement from './InventoryMang/EMRPatientEngagement'
import EMROperationalEff from './FixedAssestMang/EMROperationalEff'
import EMRSecurity from './SupportAndComplain/EMRSecurity'

const Emr = () => {
  return (

    <div className='Hospitals-Managements'>
      <div className="Hospital-Management-Banner">
        <EMRMangBanner />

      </div>
      <div className="HosCompareAna">
        <EMRSys />

      </div>
      <div className="HosAnalysis-Lan">
        <EMREPrescribing />
      </div>
      <div className="HosCompareAna">
        <EMRInteroperability />
      </div>
      <div className="HosAnalysis-Lan">
        <EMRPatientEngagement />
      </div>
      <div className="HosCompareAna">
        <HumanAttendManag />
      </div>
      <div className="HosAnalysis-Lan">
        <EMROperationalEff />
      </div>
      <div className="HosCompareAna">
        <EMRSecurity/>
      </div>
      
      <div className="HosAnalysisFeature-Lan">
        <HosAnalysisFeature />
      </div>
      <div className="HospitalHealthMang-Lan">
        <HospitalHealthMang />
      </div>
      <div className="HosAnaylysisRes-Lan">
        <HosAnaylysisRes />

      </div>
      <div className="ProductSlider-Lan">
        <ProductSlider />
      </div>
      <div className="ContactForDemo-Lan">
        <ContactForDemo />
      </div>
    </div>
  )
}

export default Emr