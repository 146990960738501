import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/HUman.png"
const LabDigital = () => {
    return (
        <div className="CompareAnalysis-2" data-aos="zoom-in-left" style={{ width: "90%", margin: "auto" }}>
            <div className="CompareAnalysis-Def">
                <h2>
                    Digital Transformation and Connected Flows:
                </h2>
                <p>
                    The LMS facilitates a fully integrated and digital approach to laboratory processes. This connectivity streamlines workflows and enables seamless data flow, which is crucial for enhancing operational efficiency.
                </p>

            </div>
            <div className="CompareAnalysis-Photo">
                <img src={Photo} alt="" />
            </div>
        </div>
    )
}

export default LabDigital