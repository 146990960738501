import React from 'react'
import "./OurServiceCounter.css"
import OurServiceCounterData from '../../../DummyData/OurServiceCounterData'
const OurServiceCounter = () => {
    return (
        <div className='OurServiceCounter'>

            {OurServiceCounterData.map((item, index) => (
              


            <div className="OurServiceCounter-1" key={index}>
                <div className="OurServiceCounter-Icon">
                    <img src={item.Icon} alt="" />
                </div>
                <div className="OurServiceCounter-Count">
                    <h3>{item.Count}</h3>
                </div>
                <div className="OurServiceCounter-Lable">
                    <h4>{item.Lable}</h4>
                </div>
                
            </div>
                
            ))}



        </div>
    )
}

export default OurServiceCounter