import A from "../Assests/OurService/ic_outline-settings.png"
import B from "../Assests/OurService/customer-service.png"
export const OurServiceNewsTemplateDataMid = [
    {
        Icon: A,
        Lable: "Implementation & Integration",
        Defination: "“Seamless setup and integration with existing systems to minimize disruptions. ”",
    },
    {
        Icon: B,
        Lable: "Training & Support",
        Defination: "“Comprehensive training for staff, along with ongoing support to address any queries and issues.” ",
    },
];

export const OurServiceNewsTemplateDataRight = [
    {
        Lable: "Our Mission",
        Defination: "“ Our mission is to enhance patient care, streamline operations, and empower healthcare providers through our comprehensive and innovative EMR software solution. ”"
   },
    {
        Lable: "Our Commitment",
        Defination: "“our commitment is steadfast. We dedicate ourselves to enhancing healthcare delivery by providing a reliable, efficient, and innovative EMR software solution. ”",
    },
    {
        Lable: "Our Vision",
        Defination: "“we envision a future where healthcare is seamlessly integrated, efficient, and patient-centric.”"
  },
];