import React from 'react'
import HospitalManag from "../../../Assests/Dashgif.gif"
import "./SuccesRate.css"
import Effi from "../../../Assests/Logo/SuccessRatLogo/efficiency_7009973 1.png"
import GroupsIc from "../../../Assests/Logo/SuccessRatLogo/Group 1000002837.png"

import DataBass from "../../../Assests/Logo/SuccessRatLogo/icon-park_data.png"

const SuccesRate = () => {
    return (
        <div className='SuccesRate'>
            <div className="SuccesRate1 ">
                <div className="SuccesRate-Each" data-aos="fade-up-right">
                    <div className="SuccesRate-Top">
                        <div className="SuccesRate-Logo">

                            <img src={Effi} alt='' />
                            <p className='SuccesRate-Pacentage'>
                                60%
                            </p>
                        </div>
                        <div className="SuccesRate-Description">
                            <div className="SuccesRate-Lable">
                                <p>
                                    Enhanced Efficiency
                                </p>
                            </div>
                            <div className="SuccesRate-Defination">

                                <p>
                                    Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SuccesRate-Each" data-aos="fade-up-right">
                    <div className="SuccesRate-Top">
                        <div className="SuccesRate-Logo">

                            <img src={GroupsIc} alt='' />
                            <p className='SuccesRate-Pacentage'>
                                80%
                            </p>
                        </div>
                        <div className="SuccesRate-Description">
                            <div className="SuccesRate-Lable">
                                <p>Improved Patient Experience</p>
                            </div>
                            <div className="SuccesRate-Defination">

                                <p>Dynamic EMR ensures a seamless patient journey from appointment scheduling to follow-up care. Patients benefit from reduced wait times, better communication, and more personalized care, leading to higher satisfaction rates.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SuccesRate-Each" data-aos="fade-up-right">
                    <div className="SuccesRate-Top">
                        <div className="SuccesRate-Logo">

                            <img src={DataBass} alt='' />
                            <p className='SuccesRate-Pacentage'>
                                100%
                            </p>
                        </div>
                        <div className="SuccesRate-Description">
                            <div className="SuccesRate-Lable">
                                <p >Comprehensive Data Management</p>
                            </div>
                            <div className="SuccesRate-Defination">

                                <p>The software provides robust data management capabilities, allowing hospitals to securely store, access, and analyze patient records and clinical data. This facilitates better clinical outcomes, compliance with healthcare regulations, and data-driven decision-making.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SuccesRate2" data-aos="fade-up-left">
                <img style={{
                    width:"100%"
                }} src={HospitalManag} alt=''/>
            </div>
        </div>
    )
}

export default SuccesRate