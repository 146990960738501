import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/compalain.png"
const EMRSecurity = () => {
    return (
        <div className="CompareAnalysis-2" data-aos="zoom-in-left" style={{ width: "90%", margin: "auto" }}>
            <div className="CompareAnalysis-Def">
                <h2>
                    Enhanced Data Security:

                </h2>
                <p>
                    EMRs use advanced encryption and access controls to protect sensitive information from unauthorized access and breaches, thereby maintaining patient confidentiality.
                </p>

            </div>
            <div className="CompareAnalysis-Photo">
                <img src={Photo} alt="" />
            </div>
        </div>
    )
}

export default EMRSecurity