import React from 'react';
import Slider from 'react-slick';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "./ProductSlider.css"
import {ProductSliderTestoData} from '../../../../DummyData/ProductSliderTestoData';
const ProductSlider = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow1 next1" onClick={onClick}>
        <IoIosArrowForward />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow1 prev1" onClick={onClick}>
        <IoIosArrowBack />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
  };

  return (
    <div className='ProductSlider'>

    <Slider {...settings}>
        {ProductSliderTestoData.map((each, index) => (


          <div className='ProductSlider-Inner' key={index}>



            <div className="ProductSlider-Des">
              <p>{each.Description}

              </p>

            </div>
            <div className="ProductSlider-Profile">
              <div className="ProductSlider-Profile-Name">
                <p>{each.Name}</p>
              </div>
              <div className="ProductSlider-Profile-Details">
                <p>{each.Position}</p>
              </div>

            </div>
          </div>
        ))}


    </Slider>
    </div>

  );
};


export default ProductSlider;
;
