import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import "./AboutTeamTab.css";

const AboutTeamTab = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/members');
        setTeamMembers(response.data);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchTeamMembers();
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow nextTab" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prevTab" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="TeamStorySlider">
      <div className="TeamStorySlider-Header" data-aos="flip-left">
        <p>Our Success Team</p>
      </div>
      <div className="TeamStorySlider-SubSlider">
        <Slider dots {...settings}>
          {teamMembers.map((each, idx) => (
            <div className='AboutTeamTab' key={idx} data-aos="flip-right">
              <img src={each.image_url} alt='' />
              <div className="About-team-Name">
                <p>{each.name}</p>
              </div>
              <div className="About-team-Position">
                <p>{each.designation}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AboutTeamTab;
