import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BlogsType.css';
import LandingBlogs from '../LandingBlogs/LandingBlogs';
import { BlogsData } from '../../../DummyData/BlogsData'
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const BlogsType = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
      const fetchBlogs = async () => {
        try {
          const response = await axios.get('https://api.dynamicemr.net/api/blogs');
          setBlogs(response.data);
        } catch (error) {
          console.error('Error fetching blogs:', error);
        }
      };

      fetchBlogs();
    }, []);

  return (
    <>
      <LandingBlogs />
      <div className='BlogsType-Main'>
        {blogs.map((each, index) => (
          <div className="BlogsType-Des" key={index}>
            <div className="BlogsType-Photo">
              <NavLink to={`/Blogs/BlogsDes/${index}`}>
                <img src={each.image_url} alt='' />

              </NavLink>
            </div>
            <div className="BlogsType-Des-Inner">
              <div className="BlogsType-Profile">
                <img src={each.author_image_url} alt='' />
                <div className="BlogLatestPost-Profile1">
                  <p>{each.author}</p>
                  <p> {moment(each.updated_at).format('MMMM DD, YYYY')}</p>
                </div>
              </div>
              <div className="BlogsType-Heading">
                <NavLink to={`/Blogs/BlogsDes/${index}`} style={{ textDecoration: "none" }}> <h3>{each.title}</h3></NavLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogsType;
