import React from 'react'
import HospitalMangBanner from './HospitalMangBanner/HospitalMangBanner'
import "./Patients.css"
import HosAnalysisFeature from './HosAnalysisFeature/HosAnalysisFeature'
import HospitalHealthMang from './HospitalHealthMang/HospitalHealthMang'
import HosAnaylysisRes from './HosAnaylysisRes/HosAnaylysisRes'
import ProductSlider from './ProductSlider/ProductSlider'

import ContactForDemo from '../../Home/ContactForDemo/ContactForDemo'
import Survey from './Survey/Survey'
import SupportAndComplain from './SupportAndComplain/SupportAndComplain'
import PatientsSupply from './CompareAnalysis/PatientsSupply'
import PatientReg from './HosAnalysis/PatientReg'
import BillingAndInvoicing from './AccountManag/BillingAndInvoicing.jsx'
import PatientMonitrack from './InventoryMang/PatientMonitrack.jsx'
import ClickManage from './HumanAttendManag/ClickManage.jsx'
import PatientEng from './FixedAssestMang/FixedAssestMang'

const Patients = () => {
  return (

    <div className='Patients-Managements'>
      <div className="Patients-Management-Banner">
        <HospitalMangBanner />

      </div>
      <div className="HosCompareAna">
        <PatientsSupply />

      </div>
      <div className="HosAnalysis-Lan">
        <PatientReg/>
      </div>
      <div className="HosCompareAna">
        <BillingAndInvoicing/>
      </div>
      <div className="HosAnalysis-Lan">
        <PatientMonitrack />
      </div>
      <div className="HosCompareAna">
        <ClickManage />
      </div>
      <div className="HosAnalysis-Lan">
        <PatientEng />
      </div>
      

      <div className="HosAnalysisFeature-Lan">
        {/* <HosAnalysisFeature /> */}
      </div>
      <div className="HospitalHealthMang-Lan">
        <HospitalHealthMang />
      </div>
      <div className="HosAnaylysisRes-Lan">
        <HosAnaylysisRes />

      </div>
      <div className="ProductSlider-Lan">
        <ProductSlider />
      </div>
      <div className="ContactForDemo-Lan">
        <ContactForDemo />
      </div>
    </div>
  )
}

export default Patients