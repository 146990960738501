import React from 'react'
import "./Logo.css"
import Logo1 from "../Assests/Logo/DynamicEMR1.gif"
import Typewriter from 'typewriter-effect';
import { NavLink } from 'react-router-dom';
const Logo = () => {
  return (
    <div className='DynamicLogo'>
      <NavLink to="/"> <img src={Logo1} alt='' style={{ width: '100%' }} />
      </NavLink>


      {/* <Typewriter
        options={{
          strings: ['<p>Healthcare ERP by Paaila Technologies</p>'],

          autoStart: true,
          loop: true,
        }}
      /> */}
      {/* <p>Healthcare ERP by Paaila Technologies</p> */}
    </div>
  )
}

export default Logo