import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Privacy.css";
import ContactForDemo from '../Page/Home/ContactForDemo/ContactForDemo';

const Privacy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState({});

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/privacy_policy');
        setPrivacyPolicy(response.data);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <div className='Privacy'>
    
       
          <h3>{privacyPolicy.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy.description }}></div>
       
   
      <ContactForDemo />
    </div>
  );
};

export default Privacy;
