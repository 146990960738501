import React, { useState, useEffect } from 'react';
import "./BannerTextSlider.css"
const BannerTextSlider = () => {
    const words = ['Solution', 'Innovative', 'supports', 'Expertise', 'Service', 'Developers',];
    const colors = ['#FB4949', '#9747FF', '#007ACC', '#32CD32', '#FF00E5C9', '#3716FF',];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 1000); // Change word every second

        return () => clearInterval(interval);
    }, [words.length]);

    return (


        
        <p className='Banner-Slogan' style={{ color: "white" }}>
            Elevating Your Business
            With Innovative Software{' '}
            <span className='Banner-Slogan' style={{ color: colors[index] }}>{words[index]}</span>.
        </p>
    );
};

export default BannerTextSlider;
