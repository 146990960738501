import React from 'react'
import "./TransferHospital.css"

const TransferHospital = () => {
    
    return (
        <div className='TransferHospital-Main'>

            <div className="TransferHospital" data-aos="fade-left">
                <div >
                    <p className="TransferHospital-Heading"><span style={{color:`#0284C7`}}>Transform</span> your Hospital</p>
                </div>
                <div>

                    <p className="TransferHospital-Defination">   From streamlining workflows to enhancing the patient journey, Dynamic EMR Hospital Management Software empowers hospitals to
                        succeed in all aspects, improving efficiency and patient care seamlessly.</p>
                </div>

            </div>
        </div>
    )
}

export default TransferHospital