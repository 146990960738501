import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./BlogsLatestPost.css";
import { NavLink } from 'react-router-dom';
import moment from 'moment'

const BlogsLatestPost = () => {
    const [blogs, setBlogs] = useState([]);
    const [data, setData] = useState(true);

    const handleOnClick = () => {
        setData(data ? false : true);
    };

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://api.dynamicemr.net/api/blogs');
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className="BlogsLatestPost-Main">
            <div className="BlogLatestpost-Lable">
                <h2>Latest post</h2>
            </div>
            <div className='BlogsLatestPost-inner'>
                {data ? (
                    blogs.slice(0, 6).map((each, index) => (
                        <div className="BlogsLatestPost-Des" key={index}>
                            <div className="BlogsLatestPost-Photo">
                                <NavLink to={`/Blogs/BlogsDes/${index}`}>
                                    <img src={each.image_url} alt='' />

                                </NavLink>
                            </div>
                            <div className="BlogsLatestPost-Des-Inner">
                                <div className="BlogsLatestPost-Profile">
                                    <img src={each.author_image_url} alt='' />
                                    <div className="BlogLatestPost-Profile1">
                                        <p>{each.author}</p>
                                        <p> {moment(each.updated_at).format('MMMM DD, YYYY')}</p>
                                    </div>
                                </div>
                                <div className="BlogsLatestPost-Heading">
                                <NavLink to={`/Blogs/BlogsDes/${index}`} style={{textDecoration:"none"}}> <h3>{each.title}</h3></NavLink>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    blogs.map((each, index) => (
                        <div className="BlogsLatestPost-Des" key={index}>
                            <div className="BlogsLatestPost-Photo">
                            <img src={each.image_url} alt='' />

                            </div>
                            <div className="BlogsLatestPost-Des-Inner">
                                <div className="BlogsLatestPost-Profile">
                                    <img src={each.author_image_url} alt='' />

                                    <p>{each.author}</p>
                                    <p> {moment(each.updated_at).format('MMMM DD, YYYY')}</p>
                                </div>
                                <div className="BlogsLatestPost-Heading">
                                     <NavLink to={`/Blogs/BlogsDes/${index}`} style={{textDecoration:"none"}}> <h3>{each.title}</h3></NavLink>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <button onClick={handleOnClick}>{data ? "Load More" : 'Load Less'}</button>
        </div>
    );
};

export default BlogsLatestPost;
