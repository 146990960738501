import React from 'react'
import "./Services.css"
import OurService from './OurService/OurService'
import OurServiceCounter from './OurServiceCounter/OurServiceCounter'
import OurServiceNewsTemplate from './OurServiceNewsTemplate/OurServiceNewsTemplate'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
import OurServiceBanner from './OurServiceBanner/OurServiceBanner'
const Services = () => {
  return (
    <div className='Services'>
      <div className="OurServiceBanner-lan">
      <OurServiceBanner/>

      </div>
      <OurService />
      <OurServiceCounter />
      <OurServiceNewsTemplate />

      <div className="ContactForDemo-Home">

        <ContactForDemo />
      </div>
    </div>
  )
}

export default Services