import A from "../Assests/OurService/Counter/Vector (1).png"
import A1 from "../Assests/OurService/Counter/hospital.png"
import A2 from "../Assests/OurService/Counter/hugeicons_global-search.png"
const OurServiceCounterData = [
    {
        Icon: A,
        Count: "10+",
        Lable: "Employs",
    },
    {
        Icon: A1,
        Count: "50+",
        Lable: "Hospitals",
    },
   
    {
        Icon: A2,
        Count: "10+",
        Lable: "Year of excellence",
    },
];
export default OurServiceCounterData;