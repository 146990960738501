import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./LandingBlogs.css";
import { NavLink } from 'react-router-dom';

const LandingBlogs = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/blogs');
        // Assuming the API returns an array of blogs, take the first one for simplicity.
        setBlog(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Failed to fetch blog data.');
        setLoading(false);
      }
    };

    fetchBlog();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {blog && (
        <div className='LandingBlogs'>
          <div className="LandingBlogs-Photo">
            <NavLink to={`/Blogs/BlogsDes/0`} style={{
              textDecoration: "none"
            }}> <img src={blog.image_url || 'default-image.jpg'} alt={blog.title || 'Blog Image'} /></NavLink>
          </div>
          <div className="LandingBlogs-Description">
            <NavLink to={`/Blogs/BlogsDes/0`} style={{
              textDecoration: "none"
            }}> <h3>{blog.title || 'Untitled Blog'}</h3></NavLink>
            <p>{blog.short_description || 'No description available.'}</p>
            <div className="Profile-Container">
              <img src={blog.author_image_url || 'default-profile.jpg'} alt={blog.name || 'Author Image'} />
              <div className="Profile-Container1">
                <p>{blog.author || 'Unknown Author'}</p>
                <p>{blog.date || 'No Date Available'}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingBlogs;
