import React from 'react'
import HospitalMangBannerVideos from './HospitalMangBannerVideo/HospitalMangBannerVideos'
import { NavLink } from 'react-router-dom'
import "./HospitalMangBanner.css"
import { PatientBannerData } from '../../../../DummyData/PatientBannerData'
const HospitalMangBanner = () => {
    return (
        <div className='HospitalMangBanner'>
            <div className="HospitalMangBanner-1">
                <div className="HospitalMangBanner-1-Des">

                    <div className="HospitalMangBanner-1-Def">

                        <h2 data-aos="fade-right">
                            Standardize
                            Feedback Processes and <span className="HospitalMangBanner-1-Def-Color-Lable">Simplify
                            Stakeholder Review
                                </span>
                        </h2>
                        <p data-aos="fade-right">
                            From streamlining workflows to enhancing the patient journey, Dynamic EMR Hospital Management Software empowers hospitals to succeed in all aspects, improving efficiency and patient care seamlessly.
                        </p>
                        <div className="HospitalMangBanner-1-Def-BookDemo">
                            <NavLink to="/BookDemo"><button>Book a Demo</button></NavLink>

                        </div>

                    </div>
                    <div className="HospitalMangBanner-1-Videos">
                        <HospitalMangBannerVideos />
                    </div>
                </div>

            </div>
            <div className="HospitalMangBanner-2">

                {PatientBannerData.map((each, index) => (
                    <div className="HospitalMangBanner-2-Inner" key={index} data-aos="fade-left">
                        <div className="HospitalMangBanner-2-Inner-Lable">
                            <h2>
                                {each.Lable}
                            </h2>
                        </div>
                        <div className="HospitalMangBanner-2-Inner-Def">
                            <p>
                                {each.Description}
                            </p>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default HospitalMangBanner;