import React from 'react'
import "./TestimonialBanner.css"
import Next from "../../../Assests/quotes1.png"
import Next1 from "../../../Assests/quotes2.png"
import { QualitysoftwareData } from '../../../DummyData/QualitysoftwareData'

const TestimonialBanner = () => {
    // console.log("testomial-banner")
    return (

        <div className='TestimonialBanner'>
            <div className="TestimonialBanner-Banner">


                <img src={Next} style={{ width: "5vw", position: "relative", right: "30%" }} alt='' />


                <h3>
                    Our Testimonials
                </h3>
                <p>
                    “Transforming Healthcare, One Patient at a Time. Trusted by Professionals, Powered by Innovation.”


                </p>

                <img style={{ width: "5vw", position: "relative", left: "30%" }} src={Next1} alt='' />

            </div>
            <div className="TestimonialBanner-Des">
                {QualitysoftwareData.map((each, index) => (
                    <>
                        <div className="TestimonialBanner-Des-Lable" data-aos="zoom-out-right">
                            <p>
                                {each.Lable}

                            </p>
                        </div>
                        <div className="TestimonialBanner-Des-Def" data-aos="zoom-out-left">
                            <p>
                                {each.Description}
                            </p>
                        </div>
                    </>

                ))}
            </div>
        </div>
    )
}

export default TestimonialBanner