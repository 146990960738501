import Logo from '../Shared/Logo'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { FooterData, FooterData2, FooterData3 } from '../DummyData/FooterData/FooterData'

import "./Footer.css"
import A2 from "../Assests/Logo/footer/mailing.gif"
import A1 from "../Assests/Logo/footer/location.gif"
import A3 from "../Assests/Logo/footer/phone.gif"
import Insta from "../Assests/Logo/footer/instagram.png"
import twit from "../Assests/Logo/footer/twitter.png"
import what from "../Assests/Logo/footer/whatsapp.png"
import Fac from "../Assests/Logo/footer/giphy.gif"

const Footer1 = () => {
    return (
        <div className='Footer'>

            <div className="Footer-Top">
                <div className="Footer-Top-Phone">
                    <p>
                        Call Now:
                    </p>
                    <div className='Footer-Number'>
                        <p>+9779842086201
                        </p>                      </div>
                </div>
                <div className="Footer-Top-Logo">
                    <Logo />


                </div>
                <div className="Footer-Top-Social">

                    <div className="Footer-Social-Icons">
                        <a href="https://www.facebook.com/dynamicemr" target="_blank" rel="noopener noreferrer">
                            <img src={Fac} alt='' />

                        </a>
                        <a href="https://twitter.com/dynamicemr" target="_blank" rel="noopener noreferrer"  >
                            <img src={twit} alt='' />

                        </a>
                        {/* <a href="https://www.linkedin.com/company/dynamic" target="_blank" rel="noopener noreferrer">


                        <img src={Lin} alt='' />
                    </a> */}
                        <a href="https://www.instagram.com/dynamicemr/" target="_blank" rel="noopener noreferrer">


                            <img src={Insta} alt='' />
                        </a>
                        <a href="https://wa.me/+9779842086201" target="_blank" rel="noopener noreferrer">


                            <img src={what} alt='' />
                        </a>
                        


                        
                    </div>
                </div>
            </div>
            <div className="Footer-Bottom1">

                <div className="Footer-Bottom">
                    <div className="Footer-Lable">
                        <p>
                            About Us
                        </p>
                    </div>
                    <p>

                        DynamicEMR is a leading healthcare company that offers innovative solutions to improve patient outcomes. Our mission is to create a healthcare system that is accessible, affordable, and equitable.
                    </p>
                    <NavLink to="/BookDemo" ><button className="Footer-Button">BookDemo</button></NavLink>     {/* <BookDemoBanner/> */}
                </div>
                <div className="Footer-Bottom">
                    <div className="Footer-Lable">
                        <p>
                            Get To Know Us
                        </p>
                    </div>

                        <div>
                            {FooterData.map((each) => (
                                <div className="Footer-Nav">
                                    <NavLink to={each.Link} className="Footer-Link">{each.Components}</NavLink>
                                </div>
                            ))}</div>

                        <div>
                            {/* {FooterData2.map((each) => (
                                <div className="Footer-Nav">
                                    <NavLink to={each.Link} className="Footer-Link">{each.Components}</NavLink>
                                </div>
                            ))} */}
                        </div>



                </div>
                <div className="Footer-Bottom">
                    <div className="Footer-Lable">
                        <p>
                            Our Links
                        </p>
                    </div>
                    {FooterData3.map((each) => (
                        <div className="Footer-Nav">
                            <NavLink to={each.Link} className="Footer-Link">{each.Components}</NavLink>
                        </div>
                    ))}



                </div>
                <div className="Footer-Bottom">

                    <div className="Footer-Logo">
                        <div className="Footer-Lable">
                            <p>
                                Contact-US
                            </p>
                        </div>
                        <div className="location">
                            <div className="Foot-icon">
                                <img src={A1} alt='' />
                            </div>
                            <div className="Foot-Name">
                                <p>Prativa chowk, Naya Sombare, Biratnagar
                                </p>
                            </div>
                        </div>
                        <div className="Mail ">
                            <div className="Foot-icon">
                                <img src={A2} alt='' />
                            </div>
                            <div className="Foot-Name  " >
                                <p>
                                    paailatechnologies@gmail.com
                                    <br />
                                    dynamicemr2021@gmail.com
                                </p>
                            </div>
                        </div>
                        <div className="Phone ">
                            <div className="Foot-icon">
                                <img src={A3} alt='' />
                            </div>
                            <div className="Foot-Name">
                                <p>
                                    9842086201

                                    9802779023
                                </p>
                            </div>
                        </div>






                    </div>
                </div>


            </div>

            <div className="Footer-CopyRight">
                <p>@Copyright © 2024 Dynamic EMR. All rights reserved.</p>

            </div>

        </div>
    )
}

export default Footer1