import A3 from "../Assests/Logo/BookDemoIcon/uiw_setting.png"
import A1 from "../Assests/Logo/BookDemoIcon/Vector (1).png"

import A2 from "../Assests/Logo/BookDemoIcon/Vector (2).png"

import A4 from "../Assests/Logo/BookDemoIcon/Vector (3).png"

export const DemoType=[
  {
    Icon:A1,
    Lable:"Challanged Face by your Business",
  },
  {
    Icon:A2,
    Lable:"Challanged Face by your Business",
  },
  {
    Icon:A3,
    Lable:"Challanged Face by your Business",
  },
  {
    Icon:A4,
    Lable:"Challanged Face by your Business",
  },
  

];