export const LabBannerData=[
    {
        Lable:"Enhanced Data Management:",
        Description:"DynamicEMR's LMS enables detailed digital data capture and storage, allowing for better analytics and decision-making based on comprehensive data trends."
    },
    {
        Lable:"Collaboration and Communication:",
        Description:"Improved internal communication and collaboration due to integrated systems allow laboratory staff to work together more efficiently, leading to faster and more informed decision-making.",
    },
    {
        Lable:"Improved Quality of Care:",
        Description:"Enhanced accuracy in laboratory testing directly contributes to better patient care, ensuring that medical decisions are based on reliable data."
    },
];