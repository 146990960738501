import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./TermCondition.css";
import ContactForDemo from '../Page/Home/ContactForDemo/ContactForDemo';

const TermCondition = () => {
  const [TermConditionPolicy, setTermConditionPolicy] = useState({});

  useEffect(() => {
    const fetchTermConditionPolicy = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/terms_and_conditions');
        setTermConditionPolicy(response.data);
      } catch (error) {
        console.error('Error fetching TermCondition policy:', error);
      }
    };

    fetchTermConditionPolicy();
  }, []);

  return (
    <div className='TermCondition'>
    
       
          <h3>{TermConditionPolicy.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: TermConditionPolicy.description }}></div>
       
   
      <ContactForDemo />
    </div>
  );
};

export default TermCondition;
