
import React from 'react'
import "./AboutDef.css"
import A1 from "../../../Assests/About/Rectangle 62.png"
const AboutDef = () => {
    return (
        <div className='AboutDef'>

            <div className="AboutDes" data-aos="fade-left">
                <p>
                At DynamicEMR, we revolutionize hospital management with unparalleled service, cutting-edge technology, and industry-specific experience.We dedicate ourselves to enhancing healthcare delivery by providing a reliable, efficient, and innovative EMR software solution. Our clients can trust that we are invested in their success and the well-being of their patients. </p>
            </div>
            <div className="AboutPho" data-aos="fade-right">
                <img src={A1} alt='' />
            </div>
        </div>
    )
}

export default AboutDef