import Videos from "../../../../../Assests/bannerVideos.mp4"
import VideoPoster from "../../../../../Assests/Videos/Poster/21.10.2024_11.13.17_REC.png"

import Play from "../../../../../Assests/Logo/Vector.png"

import React, { useRef, useState } from 'react';
import './HospitalMangBannerVideos.css';

const HospitalMangBannerVideos = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
            
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="hospitalmangbannervideos">
            
            <video
                ref={videoRef}
                className="videoHospitalMaang"
                src={Videos}
                poster={VideoPoster}
                onClick={handlePlayPause}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
            />
            {!isPlaying && (
                <button className="play-button" onClick={handlePlayPause}>
                    <img src={Play} alt="Play" />
                </button>
            )}
        </div>
    );
};


export default HospitalMangBannerVideos;
