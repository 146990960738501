import React from 'react'
import "./AboutMissCommit.css"
import { CommitmentData,MissionData } from '../../../../DummyData/MissionCommitData'
const AboutMissCommit = () => {
    return (
        <div className='AboutMissCommit'>
            <div className="Mission" >
                <div className="Mission-Heading">
                    <p>Our <span className='Head-Color'> Mission</span> </p>
                </div>
                
                {
                    MissionData.map((each, index) => (
                        <div className="Mission-Des">
                            <div className="Mission-Lable" data-aos="fade-right">
                                <p>{each.Lable}</p>
                            </div>
                            <div className="Mission-Def" data-aos="fade-left">
                                <p>{each.Defination}</p>

                            </div>
                        </div>
                    ))
                }

            </div>
            <div className="Commitment" >

                <div className="Commitment-Heading">
                    <p>Our <span className='Head-Color'>Commitment</span> </p>
                </div>
                {
                    CommitmentData.map((each,index) => (
                        <div className="Commitment-Des" key={index}>
                            <div className="Commitment-Lable" data-aos="fade-right">
                                <p>{each.Lable}</p>
                            </div>
                            <div className="Commitment-Def" data-aos="fade-left">
                                <p>{each.Defination}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AboutMissCommit