import React from 'react'
import "./HomeContact.css"
import { NavLink } from 'react-router-dom'
const HomeContact = () => {
    return (
        <div className='HomeContact' data-aos="zoom-out-left">
            <div className="HomeContact-Des">
                <div className="HomeContact-Head">
                    <p data-aos="flip-right">We would love to talk
                        With <span style={{
                            color: "#E75303"
                        }}>You</span> </p>
                </div>
                <div className="HomeContact-Def">
                    <p data-aos="flip-right">When you need Software help or IT help, you need it now. DynamicEMR does not provide cookie-cutter services from 8:00 to 5:00. We provide 24/7/365 IT Help Desk and remote monitoring services. Our round-the-clock commitment goes well beyond just “being there” when problems arise..</p>
                </div>

            </div>
            <div className="HomeContact-2" >
                <NavLink to="/Contact"  ><button data-aos="flip-right"className='HomeContact-But' style={{ backgroundColor: "#014A97" }}>Contact Us</button></NavLink>
            </div>
        </div>
    )
}

export default HomeContact