export const ProductSliderTestoData=[
   {
    Description:"Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management.",
    Name:"Prabat RAj Jha",
    Position:"Manager of Dynamic Emr",
   },
   {
    Description:"Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management.",
    Name:"Prabat RAj Jha",
    Position:"Manager of Dynamic Emr",
   },
   {
    Description:"Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management.",
    Name:"Prabat RAj Jha",
    Position:"Manager of Dynamic Emr",
   },
   
];