import Last from "../Assests/Products/Hospitalmang/Screenshot (24).png"
export const TestimonialsProductData=[
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:" DynamicEMR equips healthcare providers with powerful reporting tools and real-time data analytics. This enables detailed insights into operations and patient care, facilitating informed decision-making, optimizing efficiency, and improving overall patient outcomes. Experience enhanced visibility and control with DynamicEMR’s comprehensive analytics capabilities.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        
        ObjectiveCount:"400",
    },
    {
        Image:"",
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"DynamicEMR equips healthcare providers with powerful reporting tools and real-time data analytics. This enables detailed insights into operations and patient care, facilitating informed decision-making, optimizing efficiency, and improving overall patient outcomes. Experience enhanced visibility and control with DynamicEMR’s comprehensive analytics capabilities.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
 
];