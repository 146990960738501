export const PatientBannerData=[
    {
        Lable:"Increased Efficiency:",
        Description:"EMR systems automate routine tasks like billing and scheduling, helping healthcare staff focus more on patient care rather than paperwork."
    },
    {
        Lable:"Better Coordination of Care:",
        Description:"EMR systems improve communication and information sharing among healthcare providers, thereby enhancing collaboration and coordination in patient care1.",
    },
    {
        Lable:"Patient Engagement:",
        Description:"EMR systems often come with patient portals that allow patients to access their health information, schedule appointments, and Billing information"
    },
];