import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HosAnalysisFeature.css';

const HosAnalysisFeature = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/features');
        setFeatures(response.data);
      } catch (error) {
        console.error('Error fetching features:', error);
      }
    };

    fetchFeatures();
  }, []);

    return (
        <div className='HosAnalysisFeature'>

            <div className="HosAnalysisFeature-Feature">

                {/* <div className='Slider-Main-Container'> */}

                {features.map((each, index) => {
                    return (
                        // <div className="Slider-Image-Lable-Container" key={index}>
                        <div className="HosAnalysisFeature-Feature-Inner" data-aos="fade-left">
                            <div className='HosAnalysisFeature-Feature-Image'>
                                <img src={each.image_url} alt=""
                                />
                            </div>
                            <div className="HosAnalysisFeature-Feature-Lable">
                                <p>{each.title}</p>
                            </div>
                            <div className="HosAnalysisFeature-Feature-Defination">
                                <p>{each.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                            </div>

                            {/* <p>{each.Name}</p> */}
                        </div>

                    );
                })}


                {/* </div> */}
            </div>
            <div className="HosAnalysisFeature-Def" data-aos="zoom-in-right">
                <h2>
                    Products Of DynamicEMR
                </h2>
                <p>

                    DynamicEMR offers a comprehensive suite of products designed to streamline hospital management.DynamicEMR’s integrated solutions ensure that healthcare facilities operate efficiently and provide high-quality care.
                </p>

            </div>
        </div>
    )
}

export default HosAnalysisFeature