import React from 'react'
import "./Insurance.css"
import Insurance1 from "../../Components/Assests/hands-working-digital-device-network-graphic-overlay.png"
import InsuranceBan from "../Assests/rb_1099.png"
import { InsuranceData } from '../DummyData/InsuranceData'
const Insurance = () => {
    return (
        <div className='Insurance'>
            <div className="Insurance_Banner">
                <img src={Insurance1} alt='' />
            </div>
            <div className="Insurance-Details">
                <div className="Insurance-Details-Top">
                    <div className="Insurance-Details-Top-1">
                        <h3>
                            Insurance
                        </h3>
                        <p>
                            Insurance is a financial arrangement that provides protection against potential future losses or financial risks.Integrating insurance functionalities with DynamicEMR is key to streamlining administrative tasks and improving patient care.
                        </p>
                    </div>
                    <div className="Insurance-Details-Top-2">
                        <img src={InsuranceBan} alt='' />
                    </div>
                </div>
                <div className="Insurance-Details-Bottom">
                    <h3>Insurance in DynamicEMR</h3>
                    <p>Integrating insurance functionalities with DynamicEMR (Electronic Medical Records system) can significantly streamline administrative tasks and enhance patient care. By automating insurance-related processes, healthcare providers can improve operational efficiency, reduce paperwork, and ensure that patients receive timely, accurate care.
                    </p>
                    {InsuranceData.map((each, index) => (
                        <>
                            <h4> {each.title}</h4>
                            <p> {each.description}</p>
                        </>

                    ))}

                </div>

            </div>


        </div>
    )
}

export default Insurance