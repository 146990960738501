import React from 'react'
import "./Testimonials.css"
import TestimonialBanner from './TestimonialBanner/TestimonialBanner'
 
import TestimonialLatestPost from './TestimonialLatestPost/TestimonialLatestPost'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
import TestimonilasClientSlider from './TestimonilasClientSlider/TestimonilasClientSlider'
import TestimonialsTopLatest from './TestimonialsTopLatest/TestimonialsTopLatest'

const Testimonials = () => {
  return (
    <div className='Testimonials'>
      <div className="TestimonialBanner-Lan">
        <TestimonialBanner />

      </div>
      <div className="TestimonialsTopLatest-Lan">
        <TestimonialsTopLatest />

      </div>
      <div className="TestimonilasClientSlider-Lan">
        <TestimonilasClientSlider />

      </div>

      <div className="TestimonialLatestPost-Lan">
        <TestimonialLatestPost />

      </div>
      <div className="ContactForDemo-Lan">
        <ContactForDemo />

      </div>
    </div>
  )
}

export default Testimonials