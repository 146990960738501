import React from 'react'
import HospitalMangBanner from './HospitalMangBanner/HospitalMangBanner'
import "./Hospitals.css"
import CompareAnalysis from './CompareAnalysis/CompareAnalysis'
import HosAnalysis from './HosAnalysis/HosAnalysis'
import HosAnalysisFeature from './HosAnalysisFeature/HosAnalysisFeature'
import HospitalHealthMang from './HospitalHealthMang/HospitalHealthMang'
import HosAnaylysisRes from './HosAnaylysisRes/HosAnaylysisRes'
import ProductSlider from './ProductSlider/ProductSlider'

import ContactForDemo from '../../Home/ContactForDemo/ContactForDemo'
import InventoryMang from './InventoryMang/InventoryMang'
import FixedAssestMang from './FixedAssestMang/FixedAssestMang'
import Survey from './Survey/Survey'
import AccountManag from './AccountManag/AccountManag'
import HumanAttendManag from './HumanAttendManag/HumanAttendManag'
import SupportAndComplain from './SupportAndComplain/SupportAndComplain'

const Hospitals = () => {
  return (

    <div className='Hospitals-Managements'>
      <div className="Hospital-Management-Banner">
        <HospitalMangBanner />

      </div>
      <div className="HosCompareAna">
        <CompareAnalysis />

      </div>
      <div className="HosAnalysis-Lan">
        <HosAnalysis />
      </div>
      <div className="HosCompareAna">
        <AccountManag />
      </div>
      <div className="HosAnalysis-Lan">
        <InventoryMang />
      </div>
      <div className="HosCompareAna">
        <HumanAttendManag />
      </div>
      <div className="HosAnalysis-Lan">
        <FixedAssestMang />
      </div>
      <div className="HosCompareAna">
        <SupportAndComplain />
      </div>
      <div className="HosAnalysis-Lan">
        <Survey />
      </div>
      <div className="HosCompareAna">

      </div>

      <div className="HosAnalysisFeature-Lan">
        <HosAnalysisFeature />
      </div>
      <div className="HospitalHealthMang-Lan">
        <HospitalHealthMang />
      </div>
      <div className="HosAnaylysisRes-Lan">
        <HosAnaylysisRes />

      </div>
      <div className="ProductSlider-Lan">
        <ProductSlider />
      </div>
      <div className="ContactForDemo-Lan">
        <ContactForDemo />
      </div>
    </div>
  )
}

export default Hospitals