export const ProductBannerdata=[
    {
        Lable:"Enhanced Efficiency",
        Description:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments."
    },
    {
        Lable:"Comprehensive Reporting",
        Description:"Dynamic EMR offers robust reporting capabilities that empower healthcare administrators with valuable insights.",
    },
    {
        Lable:"Improved Patient Experience",
        Description:"Dynamic EMR ensures a seamless patient journey from appointment scheduling to follow-up care. Patients benefit from reduced wait times, better communication, and more personalized care, leading to higher satisfaction rates."
    },
];