export const MissionData = [
    {
        Lable: "Unmatched service",
        Defination: "We prioritize our clients' needs, offering exceptional support and continuous improvements to ensure seamless integration and functionality.",
    },
    {
        Lable: "Specific Experience",
        Defination: "Drawing from years of expertise in healthcare IT, DynamicEMR is designed to address the unique challenges of hospital management, providing tailored solutions that enhance efficiency and accuracy. ",
    },
    
    {
        Lable: "Technology",
        Defination: "Leveraging the latest advancements, our EMR software boasts robust features, secure data management, and user-friendly interfaces, ensuring that healthcare facilities operate at their optimal capacity.",
    },
]
export const CommitmentData = [
    {
        Lable: "Ethics",
        Defination: "We uphold the highest standards of ethical conduct, ensuring that our software supports hospitals in maintaining patient confidentiality, data security, and compliance with all regulatory requirements.",
    },
    {
        Lable: "Quality",
        Defination: " Quality is at the heart of DynamicEMR. We continuously strive to deliver a robust and intuitive EMR system that meets the highest standards of performance, usability, and reliability. ",
    },
    {
        Lable: "Continuity",
        Defination: "Our software ensures continuity of care by seamlessly integrating with existing systems and providing a stable, long-term solution that evolves with the needs of healthcare providers.",
    },
]