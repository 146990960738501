import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import moment from 'moment';
import "./TestimonilasClientSlider.css";

const TestimonilasClientSlider = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/testimonials');
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 1,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "0px",
          centerMode: false,
          className: "none",
        },
      },
    ],
  };

  return (
    <div className="TestimonilasClientSliderStorySlider">
      <div className="TestimonilasClientSliderStorySlider-Header">
        <p>What Our Clients Say About Us!</p>
      </div>
      <div className="TestimonilasClientSliderStorySlider-Inner">
        <Slider {...settings}>
          {testimonials.map((each, index) => (
            <div className="TestimonilasClientSliderStorySlider-Item" key={index}>
              <div className="TestimonilasClientSliderStorySlider-Item-inner overflow-hidden">
                <div className="TestimonilasClientSliderStorySlider-Item-Photo" data-aos="zoom-in-right">
                  <img src={each.image_url} alt="" />
                </div>
                <div className="TestimonilasClientSliderStorySlider-Item-Details">
                  <div className="TestimonilasClientSliderStorySlider-Date">
                    <p data-aos="fade-left">{moment(each.created_at).format('MMMM DD, YYYY')}</p>
                  </div>
                  <div className="TestimonilasClientSliderStorySlider-Tittle">
                    <h2 data-aos="fade-left">{each.name}</h2>
                    <p data-aos="fade-left">{each.designation}</p>
                  </div>
                  <div className="TestimonilasClientSliderStorySlider-Describe">
                    <div dangerouslySetInnerHTML={{ __html: each.description }} data-aos="fade-left"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonilasClientSlider;
