import Icon from "../../Assests/Logo/FeatureIcon/iconoir_journal.png"
const NavPath = [
    {
        Link: "/",
        Lable: "Home",
        ProductData: [],

    },
    {
        Link: "/Aboutsus",
        Lable: "Abouts",
        ProductData: [],

    },
    {
        Link: "/Service",
        Lable: "Services",
        ProductData: [],

    },
    {
        // Link: "/Products",
        Lable: "Products",
      
    },

    // {
    //     Link: "/Testimonials",
    //     Lable: "Testimonials",
    //     ProductData: [],

    // },
    {
        Link: "/Contact",
        Lable: "Contact Us",
        ProductData: [],

    },
    {
        Link: "/FAQS",
        Lable: "FAQS",
        ProductData: [],

    },
    {
        Link: "/Blogs",
        Lable: "Blogs",
        ProductData: [],

    },
    {
        Link: "",
        Lable: "",
        ProductData: [],

    },

]
export default NavPath;
export const ProductData= [{
    Link: "/Hospitals",
    Icon: Icon,

    Lable: "Hospitals Management Systems",
    Defination: "DynamicEMR streamlines hospital operations with efficient patient management, billing, scheduling, compliance, and data security, enhancing overall healthcare delivery.",
},
{
    Link: "/Patients",
    Icon: Icon,

    Lable: "Patients Management Systems",
    Defination: "DynamicEMR’s Patient Management Systems streamline patient data handling, scheduling, billing, and communication, enhancing overall healthcare efficiency and patient care.",
},
{
    Link: "/Laboratory",
    Icon: Icon,

    Lable: "Laboratory Management Systems",
    Defination: "A Laboratory Management System (LIMS) is crucial software that streamlines lab operations,and  enhances data management. It ensures sample tracking, compliance, and efficiency in laboratories.",
},
{
    Link: "/EMR",
    Icon: Icon,

    Lable: "EMR",
    Defination: "EMRs are digital patient records enhancing healthcare efficiency, facilitating information access, improving care coordination, and reducing medical errors.",
},
]