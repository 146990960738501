import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Features.css";

const Features = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.dynamicemr.net/api/services');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPosts();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className='Features'>
      <div className="Feature-Heading">
        <p>What makes us unique?</p>
      </div>
      <Slider {...sliderSettings} className="Slider-Main-Container">
        {posts.map((each, index) => (
          <div className="Slider-Image-Lable-Container" key={index}>
            <div className="Features-Inner" data-aos="flip-left">
              <div className='Slider-Image-Container'>
                <img src={each.image_url} alt="" />
              </div>
              <div className="Features-Lable">
                <p>{each.title}</p>
              </div>
              <div className="Features-Defination">
                <p>{each.short_description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Features;
