 

import React from 'react'
import "./Laboratory.css"
import HosAnalysisFeature from './HosAnalysisFeature/HosAnalysisFeature'
import HospitalHealthMang from './HospitalHealthMang/HospitalHealthMang'
import HosAnaylysisRes from './HosAnaylysisRes/HosAnaylysisRes'
import ProductSlider from './ProductSlider/ProductSlider'

import ContactForDemo from '../../Home/ContactForDemo/ContactForDemo'
import Survey from './Survey/Survey'
import SupportAndComplain from './SupportAndComplain/SupportAndComplain'
import  Compliance from './InventoryMang/Compliance.jsx'
import PatientEng from './FixedAssestMang/FixedAssestMang'
import LabDef from './CompareAnalysis/LabDef.jsx'
import LabIntegratedBill from './HosAnalysis/LabIntegratedBill.jsx'
import SampleManagement from './AccountManag/SampleManagement.jsx'
import LabDigital from './HumanAttendManag/LabDigital.jsx'
import LabMangBanner from './HospitalMangBanner/LabMangBanner.jsx'

const Laboratory = () => {
  return (

    <div className='Patients-Managements'>
      <div className="Patients-Management-Banner">
        <LabMangBanner />

      </div>
      <div className="HosCompareAna">
        <LabDef />

      </div>
      <div className="HosAnalysis-Lan">
        <LabIntegratedBill/>
      </div>
      <div className="HosCompareAna">
        <SampleManagement/>

      </div>
      <div className="HosAnalysis-Lan">
        < Compliance />
      </div>
      <div className="HosCompareAna">
        <LabDigital />
      </div>
      
      <div className="HospitalHealthMang-Lan">
        <HospitalHealthMang />
      </div>
      <div className="HosAnaylysisRes-Lan">
        <HosAnaylysisRes />

      </div>
      <div className="ProductSlider-Lan">
        <ProductSlider />
      </div>
      <div className="ContactForDemo-Lan">
        <ContactForDemo />
      </div>
    </div>
  )
}

export default Laboratory