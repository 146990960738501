import React from 'react'
import "./HosAnalysis.css"
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const EMREPrescribing = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    E-Prescribing
                </h2>
                <p>
                    EMR systems facilitate electronic prescriptions, which improve accuracy and safety in medication management by minimizing handwriting errors and allowing for drug interaction checks.
                </p>

            </div>
        </div>
    )
}

export default EMREPrescribing