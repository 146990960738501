import React from 'react'
import "./CompareAnalysis.css"
import { NavLink } from 'react-router-dom'
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const LabDef = () => {
    return (
        <div className='CompareAnalysis'>
            <div className="CompareAnalysis-1" data-aos="zoom-in-right">
                <h3>
                    DynamicEMR for Labs with the Lab Information management system Software
                </h3>
                <p>
                    The Laboratory Management System integrated into DynamicEMR provides a comprehensive solution for laboratory management.You can now enable digital transformation with connected flows. Reduce time for reporting, be compliant to standards, and improve care delivery.   DynamicEMR connected queue system allows billing to be connected to the Lab. Technicians can view billed requests, collect samples, report results, print reports  . Advanced error checking formulas will make your reports error free.
                </p>
            </div>
            <div className="CompareAnalysis-2" data-aos="zoom-in-left">
                <div className="CompareAnalysis-Def">
                    <h2>
                        Electronic Medical Record (EMR) Management:
                    </h2>
                    <p>
                        DynamicEMR provides a robust EMR system that securely stores patient records, facilitating easy access for healthcare providers. This digital storage helps in maintaining comprehensive health histories, including medications, allergies, and treatment plans1.

                    </p>
                    <NavLink to="/BookDemo" data-aos="flip-left">
                        <button className='CompareAnalysis-Def-But' >Book a Demo</button>
                    </NavLink>
                </div>
                <div className="CompareAnalysis-Photo">
                    <img src={Photo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default LabDef