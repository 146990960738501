import React from 'react'
import "./TestimonialsTopLatest.css"
import { BlogsData } from '../../../DummyData/BlogsData'
 
import TestimonialTopProduct from '../TestimonialTopProduct/TestimonialTopProduct'
const TestimonialsTopLatest = () => {
    return (<div className='TestimonialsTopLatest overflow-hidden'>

        <div className="TestimonialTopProduct-lan">
            <TestimonialTopProduct />

        </div>
        <div className='TestimonialsTopLatest-inner'>
            <h2 data-aos="flip-left">Latest Success Stories</h2>

            <div className='TestimonialsTopLatest-Main'>

                {BlogsData.map((each, index) => (
                    <div className="TestimonialsTopLatest-Des" key={index} >

                        <div className="TestimonialsTopLatest-Photo"  data-aos="zoom-in-right">
                            <img src={each.Photo} alt='' />

                        </div>
                        <div className="TestimonialsTopLatest-Des-Inner">
                            <div className="TestimonialsTopLatest-Profile" data-aos="fade-left">
                                <img src={each.Profile} alt='' />
                                <div className="TestimonialsTopLatest-Profile1" >
                                    <p>{each.Name}</p>
                                    <p>{each.Date}</p>
                                </div>

                            </div>

                            <div className="TestimonialsTopLatest-Heading" data-aos="zoom-in-right">
                                <h3>{each.Heading}</h3>

                            </div>

                        </div>
                        <button data-aos="flip-right">View Success Story</button>

                    </div>
                ))}
            </div>
        </div>

    </div>

    )
}

export default TestimonialsTopLatest