import React from 'react'
import "./OurServiceBanner.css"
import Next from "../../../Assests/OurService/Banner/Colorful Clean 5 Point Main Map Brainstrom copy1.png"
import { QualitysoftwareData } from '../../../DummyData/QualitysoftwareData'
import Typewriter from 'typewriter-effect';


const OurServiceBanner = () => {
    return (
        <div className='OurServiceBanner'>
            <div className="OurServiceBanner-Banner">
                {/* <img src={Next} style={{ width: "100%", height: "80vh", position: "relative", }} alt='' /> */}

                <div className="OurServiceBanner-Banner-inner">
                    <h3>
                        Our Service
                    </h3>


                    <Typewriter
                        options={{
                            strings: [' <p>DynamicEMR offers a suite of services to ensure hospitals can operate with maximum efficiency and patient-centric care</p>'],
                            autoStart: true,
                            loop: true,
                        }}

                    />
                </div>
                <div></div>


            </div>
            <div className="OurServiceBanner-Des">
                {QualitysoftwareData.map((each, index) => (
                    <>
                        <div className="OurServiceBanner-Des-Lable" data-aos="fade-right" key={index}>
                            <p>
                                {each.Lable}
                            </p>
                        </div>
                        <div className="OurServiceBanner-Des-Def" data-aos="fade-left">
                            <p>

                                {each.Description}
                            </p>
                        </div>
                    </>

                ))}

            </div>
        </div>
    )
}

export default OurServiceBanner