import React from 'react';
import Typewriter from 'typewriter-effect';
import "./AboutmapSlogan.css";

const AboutmapSlogan = () => {
    return (
        <div className="AboutSloganmap">
            <Typewriter
                options={{
                    strings: [
                        '<p>We’re here for you',
                        '<span class="AboutSlogan-1">no matter where you are</span><p>'
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                    delay: 75,
                    pauseFor: 2000,
                    html: true, // This option allows HTML tags in the strings
                }}
            />
        </div>
    );
}

export default AboutmapSlogan;
