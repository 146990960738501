import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./DetailBlogs.css";
import { useParams } from 'react-router-dom';
import BlogsLatestPost from '../BlogsLatestPost/BlogsLatestPost';

const DetailBlogs = () => {
    const { index } = useParams();
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://api.dynamicemr.net/api/blogs');
                const data = response.data.map(item => ({
                    ...item,
                    updatedDescription: item.description.replace(/src="\/ckeditor\/kcfinder\/upload\/images/g, `src="https://api.dynamicemr.net/ckeditor/kcfinder/upload/images`)
                }));
                setBlogs(data); // Use mapped data here
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    if (!blogs.length) {
        return <div>Loading...</div>;
    }

    const blog = blogs[parseInt(index)];

    return (<>
        <div className='Blogs-description'>
            {blog ? (
                <div className='DetailBlogs'>
                    <div className="DetailBlogs-title">
                    <h2>{blog.title}</h2>

                    </div>

                    <div className='DetailBlogs-inner'>
                        <img src={blog.image_url} alt='' />
                    </div>
                    <div className='DetailBlogs-Description'>
                        <div dangerouslySetInnerHTML={{ __html: blog.updatedDescription }}></div> {/* Use blog here */}
                    </div>
                </div>
            ) : (
                <div>Blog not found</div>
            )}
        </div>
        <BlogsLatestPost/>
    </>

    );
};

export default DetailBlogs;
