import React from 'react'
import "./AboutMap.css"
import map1 from "../../../Assests/About/Mapwrap.png"
const AboutMap = () => {
  return (
    <div className='AboutMap' data-aos="fade-up">
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50512135.64200933!2d81.29813616842583!3d39.401142002711566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1725265192038!5m2!1sen!2snp" style={{width:"100%",height:"40vh",border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='connect with Paaila Technology'></iframe> */}
        <img src={map1} alt='' />
    </div>
  )
}

export default AboutMap