import React from 'react'
import "./HosAnalysis.css"
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const PatientReg = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                Patient Admission and Registration:
                </h2>
                <p>
                The software enables easy and quick registration of patients upon admission. It gathers all necessary details, including personal information and medical history, ensuring a smooth initial assessment1.
                </p>

            </div>
        </div>
    )
}

export default PatientReg