import Home from "../../Page/Home/Home"
import AboutUs from "../../Page/Abouts/AboutUS"

import Services from "../../Page/Services/Services"

import Products from "../../Page/Products/Products"
import ContactUs from "../../Page/ContactUs/ContactUS"
import FAQS from "../../Page/FAQS/FAQS"
import Blogs from "../../Page/Blogs/Blogs"
import Login1 from "../../Login/Login1"
import Register from "../../Login/Register/Register"
import BookDemo from "../../../Components/Login/BookDemo/BookDemo"
import Privacy from "../../Privacy/Privacy";
import TermCondition from "../../TermCondition/TermCondition"
import Hospitals from "../../Page/Products/Hospitals/Hospitals"
import Patients from "../../Page/Products/Patients/Patients"
import Emr from "../../Page/Products/Emr/Emr"
import Testimonials from "../../Page/Testimonials/Testimonials"
import HospitalHealthMang from "../../Page/Products/Hospitals/HospitalHealthMang/HospitalHealthMang"
import Laboratory from "../../Page/Products/Laboratory/Laboratory"
import DetailBlogs from "../../Page/Blogs/DetailBlogs/DetailBlogs"
import SSF from "../../SSF/SSF"
import Insurance from "../../Insurance/Insurance"

const NavData = [
    {
        Path: "/",
        Component: <Home />,
    },
    {
        Path: "/Aboutsus",
        Component: <AboutUs />,
    },
    {
        Path: "/Service",
        Component: <Services />,
    },
    {
        Path: "/Products",
        Component: <Products />,
    },
    {
        Path: "/Testimonials",
        Component: <Testimonials />,
    },
    {
        Path: "/Contact",
        Component: <ContactUs />,
    },
    {
        Path: "/FAQS",
        Component: <FAQS />,
    },
    {
        Path: "/Blogs",
        Component: <Blogs />,
    },
    // {
    //     Path: "/Login",
    //     Component: <Login1 />
    // },
    {
        Path: "/Register",
        Component: <Register />
    },
    {
        Path:"/BookDemo",
        Component: <BookDemo />
    },
    {
        Path:"/PrivacyPolicy",
        Component:<Privacy/>,
    },
    {
        Path:"/TermsAndConditions",
        Component:<TermCondition/>
    },
    {
        Path: "/Hospitals",
        Component:<Hospitals/>,
    },
    {
        Path: "/Patients",
        Component:<Patients/>,
    },
    {
        Path: "/Laboratory",
        Component:<Laboratory/>,
    },
    {
        Path: "/EMR",
        Component:<Emr/>,
    },
    {
        Path: "/Blogs/BlogsDes/:index",
        Component:<DetailBlogs/>,
    },
    {
        Path: "/SSF",
        Component:<SSF/>,
    }, {
        Path: "/Insurance",
        Component:<Insurance/>,
    },
];
export default NavData;