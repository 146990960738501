import React from 'react'
import "./ContactUs.css"
import RegForm from './RegForm/RegForm'
import Banner from './Banner/Banner'
import DynamicContactAdd from './DynamicContactAdd/DynamicContactAdd'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
import ContMap from './ContMap/ContMap'
const ContactUS = () => {
  return (
    <div className='ContactUs'>
      <div className="Banner-lan">
      <Banner />
      {/* <img src={Contact} alt="" /> */}

      </div>
      <RegForm />
      <div className="DynamicContactAdd-lan">
      <DynamicContactAdd />

      </div>
      <ContMap/>
      <ContactForDemo/>
    </div>
  )
}

export default ContactUS