import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "./ClientSlider.css"
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const ClientSlider = () => {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get('https://api.dynamicemr.net/api/clients');
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft />
            </div>
        );
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {clients.map((client, index) => (

                    <div key={index}>
                        <NavLink to={client.link} style={{ textDecoration: "none" }}>
                            <h5 style={{color:"#024995"}}>{client.title}</h5>
                            <img src={client.image_url} alt='' />
                        </NavLink>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ClientSlider;
