




import React from 'react'
import Photo from "../../../../Assests/Products/Hospitalmang/Fixedassests.png"
const EMROperationalEff = () => {

    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    Operational Efficiency:
                </h2>
                <p>
                    By automating administrative tasks such as scheduling, billing, and record-keeping, EMRs significantly reduce the time healthcare professionals spend on paperwork, enabling them to focus more on patient care
                </p>

            </div>
        </div>
    )
}

export default EMROperationalEff

