
import React from 'react';
// import "./Login.css"
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
}
    from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';

function Register() {
    return (
        <MDBContainer fluid>
            <MDBRow>

                <MDBCol sm='6'>

                    <div className='d-flex  flex-row ps-2 pt-3'>
                        <MDBIcon fas icon="crow fa-3x me-3" style={{ color: '#709085' }} />
                        <span className="h1 fw-bold mb-0" style={{ color: "#014A97" }}>Register</span>
                    </div>

                    <div className='d-flex flex-column justify-content-center  h-custom-2 w-75 pt-4'>
                        <p className='ms-5'>Already Have an Account? <NavLink to="/Login" style={{ color: "#E75303" }}>Login?</NavLink></p>
                        <div className='UserName'>

                            <lable className="small mb-4 pb-lg-3 ms-5" style={{ float: "left" }}>UserName</lable>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' name='UserName' id='formControlLg' type='text' size="lg" />
                        </div>
                        <div className='Email'>
                            <lable className="small mb-4 pb-lg-3 ms-5" style={{ float: 'left' }}>Email Address</lable>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' name='Email' id='formControlLg' type='email' size="lg" />
                        </div>
                        <div className="Password">

                            <lable className="small mb-4 pb-lg-3 ms-5" style={{ float: "left" }}>Password</lable>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' name='Password' id='formControlLg' type='password' size="lg" />
                        </div>

                        <MDBBtn className="mb-4 px-5 mx-5 w-100" size='lg' style={{ backgroundColor: "#E75303" }}>Register</MDBBtn>
                        <p className="small mb-4 pb-lg-3 ms-5" style={{ textAlign: "center" }}>Already Have an Account <NavLink class="text-muted" href="/Login">Login?</NavLink></p>



                    </div>

                </MDBCol>

                <MDBCol sm='6' className='d-none d-sm-block px-0'>
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img3.webp"
                          alt="Login image" className="w-100" style={{ objectFit: 'fill', objectPosition: 'left' ,height:"75vh"}} />
                </MDBCol>

            </MDBRow>

        </MDBContainer>
    );
}

export default Register;