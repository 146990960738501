import React from 'react'
import "./ContMap.css"
const ContMap = () => {
    return (
        <div className='ContMap'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4248.361953480774!2d87.28479011427179!3d26.441789520140464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef75688ccbaf33%3A0x12e23418a0c4980b!2sDynamicEMR!5e0!3m2!1sen!2snp!4v1725193553334!5m2!1sen!2snp" style={{width:"100%", height:"50vh" ,style:"border:0" ,allowfullscreen:"", loading:"lazy" ,referrerpolicy:"no-referrer-when-downgrade"}} title='DynamicEmr Paaila Technology'></iframe>
        </div>
    )
}

export default ContMap