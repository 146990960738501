import React from 'react'
import "./Home.css"
import BannerSlider from "./BannerSlider/BannerSlider"
import VideoSlider from "./VideoSlider/VideoSlider"
import TransferHospital from "./TransferHospital/TransferHospital"
import SuccesRate from "./SuccesRate/SuccesRate"
import ClientStorySlider from "./ClientStorie/ClientStorySlider"
import Features from "./Features/Features"
import DyanamicManag from "./DyanamicManag/DyanamicManag"
import HomeContact from "./HomeContact/HomeContact"
import ContactForDemo from "./ContactForDemo/ContactForDemo"
const Home = () => {
  return (
    <div className='Home-Animation'>
      <div className="BannerSlider-Lan">
        <BannerSlider />

      </div>
      <VideoSlider />
      <TransferHospital />
      <SuccesRate />
      <div className="Home-Client-Story Back-Color-White">
        {/* <ClientStorySlider /> */}

      </div>
      <div className="Feature-Slider">

        <Features />
      </div>
      <DyanamicManag />
      <div className="Home-HomeContactUS"   >
        <HomeContact />

      </div>
      <div className="ContactForDemo-Home">
        <ContactForDemo />

      </div>
    </div>
  )
}

export default Home