import React from 'react'
import "./CompareAnalysis.css"
import { NavLink } from 'react-router-dom'
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const PatientsSupply = () => {
    return (
        <div className='CompareAnalysis'>
            <div className="CompareAnalysis-1" data-aos="zoom-in-right">
                <h3>
                Patient Management Software for Supply Better Performance for Clinics, Hospitals & Doctors
                </h3>
                <p>
                DynamicEMR is an advanced Patient Management Software designed to enhance the operational efficiency and performance of clinics, hospitals, and medical practitioners. Its features support end-to-end workflows, effectively managing patient interactions from the moment of admission to discharge
                </p>
            </div>
            <div className="CompareAnalysis-2" data-aos="zoom-in-left">
                <div className="CompareAnalysis-Def">
                    <h2>
                    Electronic Medical Record (EMR) Management:
                    </h2>
                    <p>
                    DynamicEMR provides a robust EMR system that securely stores patient records, facilitating easy access for healthcare providers. This digital storage helps in maintaining comprehensive health histories, including medications, allergies, and treatment plans1.

                    </p>
                    <NavLink to="/BookDemo" data-aos="flip-left">
                        <button className='CompareAnalysis-Def-But' >Book a Demo</button>
                    </NavLink>
                </div>
                <div className="CompareAnalysis-Photo">
                    <img src={Photo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default PatientsSupply