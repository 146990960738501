import React from 'react'
import "./BookDemoBanner.css"
import { DemoType } from '../../../DummyData/DemoType';





const BookDemoBanner = () => {
    return (
        <div className='BookDemoBanner overflow-hidden'>
            <div className="BookDemoBanner-Des">
                <h3 data-aos="fade-right">
                What To Expect In Demo
                </h3>

                <label data-aos="fade-right">Experience the difference with DynamicEMR !!</label>
                <p data-aos="fade-right">Dynamic EMR focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management. Our software automates administrative tasks, reduces paperwork, and optimizes workflows, enabling healthcare providers to increase efficiency and productivity. By ensuring a seamless patient journey—from scheduling to follow-up care—we improve patient experiences and satisfaction. Additionally, our robust data management capabilities allow secure storage, easy access, and thorough analysis of patient records, supporting informed decision-making and compliance with healthcare regulations.</p>
            </div>
            <div className="BookDemoBanner-Feature">
                {DemoType.map((each, index) => (
                    <div className="BookDemoBanner-Feature-Inner" key={index} data-aos="fade-left">

                        <div className="BookDemoBanner-Feature-Inner1" >
                            <img src={each.Icon}  alt=''/>
                            <label >{each.Lable}</label>
                        </div>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default BookDemoBanner